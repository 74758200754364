<template>
  <div>
    <v-dialog v-model="dialog" scrollable max-width="620px">
      <v-card>
        <v-card-title>
          <v-row align="center">
            <v-col cols="1">
              <v-btn icon @click=";(dialog = false), resetFormInput()">
                <v-icon size="20">
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-col>

            <v-col align="center">
              <h5>
                {{ editData.hasOwnProperty('editPage') ? 'Edit Loan Submission' : 'Create Loan Submission' }}
              </h5>
            </v-col>

            <v-col cols="1"></v-col>
          </v-row>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="py-8 px-8">
          <v-snackbar v-model="showSnackbarErr" color="error" :timeout="2000" :top="true" style="z-index: 5 !important">
            {{ errMessage }}
            <template #action="{ attrs }">
              <v-btn text v-bind="attrs" @click="closeShowSnackbarErr()">
                {{ $t('close') }}
              </v-btn>
            </template>
          </v-snackbar>
          <v-select
            v-model="loanTypeId"
            item-text="loanName"
            item-value="id"
            :items="loanTypes"
            label="Loan Type"
            no-data-text="You need to create a loan type first."
            outlined
            :rules="loanTypeRules"
          ></v-select>
          <v-text-field v-model="name" label="Loan name" outlined>
            <!-- :rules="totalLoanRules" -->
          </v-text-field>
          <v-text-field v-model="totalLoan" label="Total Loan" outlined :rules="totalLoanRules"> </v-text-field>
          <div v-if="!isRecipientListed" class="mb-8">
            <p>
              <strong>Recipient is not listed</strong><br />
              <span>recipient name : {{ loanSub.recipient.name }}</span
              ><br />
              <span>recipient phone number : {{ loanSub.recipient.phone_number }}</span
              ><br />
            </p>
          </div>
          <v-combobox
            v-if="isRecipientListed"
            v-model="recipient"
            :items="customerList"
            item-text="name"
            label="Recipient Name"
            outlined
            return-object
            :rules="recipientNameRules"
          >
          </v-combobox>
          <!-- <VuePhoneNumberInput
          v-model="recipientPhoneNumber"
          label="Recipient Phone Number"
          class="mb-8"
          color="#F15858"
          valid-color="#F15858"
          error-color="#FF4C51"
          default-country-code="ID"
          clearable
          size="lg"
          :dark="isDark"
          :error="recipientPhoneNumber ? !recipientPhoneNumber || Boolean(errors.length) : Boolean(errors.length)"
          @update="(data) => recipientPhoneNumber = data"
        /> -->
          <v-text-field
            v-model="recipientPhoneNumber"
            label="Recipient Phone Number"
            :rules="recipientPhoneRules"
            outlined
          >
          </v-text-field>
          <v-select
            v-if="isRecuringBiling"
            v-model="loanSub.loanDuration"
            item-text="text"
            item-value="value"
            :items="loanDuration[bilingPeriod]"
            label="Loan Duration"
            :rules="loanDurationRules"
            outlined
          ></v-select>

          <div v-if="isAdditionalExist" style="display: flex; justify-content: center">
            <v-btn
              text
              color="primary"
              class="text-capitalize font-weight-bold"
              @click="showAdditionalSurvey = !showAdditionalSurvey"
            >
              <span :class="loanSub.additional !== null || isAdditionalFilled ? 'me-3' : ''"> Additional Data </span>
              <v-icon v-if="loanSub.additional !== null || isAdditionalFilled" size="20">
                {{ icons.mdiCheck }}
              </v-icon>
            </v-btn>
          </div>
          <div v-if="showAdditionalSurvey">
            <survey :survey="survey" />
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="createLoanSub"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog alert -->
    <v-dialog v-model="dialogAlert" width="500">
      <v-card>
        <v-card-title color="error">
          {{ $t('warning') }}
        </v-card-title>
        <v-divider class="" />

        <v-card-text>
          {{ alertText }}
        </v-card-text>
        <v-divider class="" />

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="dialogAlert = false">
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable no-return-assign */
/* eslint-disable eqeqeq */
/* eslint-disable */
import useAppConfig from '@core/@app-config/useAppConfig'
import VuePhoneNumberInput from 'vue-phone-number-input'
import { mdiClose, mdiCheck } from '@mdi/js'
import * as SurveyVue from 'survey-vue'
import contactMixin from '../../../../mixins/contactMixin'
import 'survey-vue/defaultV2.css'

SurveyVue.StylesManager.applyTheme('defaultV2')

export default {
  name: 'FormLoanSub',
  components: { VuePhoneNumberInput },
  props: {
    value: { type: Boolean, required: false },
    editData: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  mixins: [contactMixin],
  data() {
    const json = {
      showProgressBar: 'top',
      pages: [
        {
          questions: [
            {
              type: 'text',
              name: 'default1',
              title: 'This is default survey',
            },
          ],
        },
      ],
    }
    const survey = new SurveyVue.Model(json)

    return {
      survey,
      surveyJsonQuestion: json,
      isAdditionalExist: false,
      showAdditionalSurvey: false,
      isAdditionalFilled: false,
      loanDuration: {
        monthly: Array.from({ length: 12 }, (_, i) => {
          return {
            value: i + 1,
            text: i + 1 === 1 ? `${i + 1} month` : `${i + 1} months`,
          }
        }),
        weekly: Array.from({ length: 52 }, (_, i) => {
          return {
            value: i + 1,
            text: i + 1 === 1 ? `${i + 1} week` : `${i + 1} weeks`,
          }
        }),
      },
      bilingPeriod: 'monthly',
      isRecuringBiling: true,
      loanTypeId: null,
      recipient: null,
      name: null,
      isRecipientListed: true,
      filterAllContact: '',
      allContact: [],
      custList: [],
      totalLoan: null,
      errors: [],
      isEdit: false,
      recipientPhoneNumber: '',
      dialogAlert: false,
      alertText: '',
      showSnackbarErr: false,
      errMessage: '',

      loanDurationRules: [value => !!value || 'Loan duration wajib diisi'],
      loanTypeRules: [value => !!value || 'Loan Type wajib diisi'],
      totalLoanRules: [value => !!value || 'Total Loan wajib diisi'],
      recipientNameRules: [value => !!value || 'Recipient name wajib diisi'],
      recipientPhoneRules: [value => !!value || 'Recipient phone number wajib diisi'],
    }
  },
  setup() {
    const { isDark } = useAppConfig()
    return {
      isDark,
      icons: { mdiClose, mdiCheck },
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    projects() {
      return this.$store.getters['manageProject/getProjects']
    },
    project() {
      return this.$store.getters['manageSalary/getCurrentProject']
    },
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    loanSub() {
      return {
        recipient: this.editData?.recipient ? this.editData?.recipient : this.recipient,
        loanTypeId: this.editData?.loanTypeId?.id ? +this.editData?.loanTypeId?.id : this.loanTypeId,
        totalLoan: this.totalLoan,
        name: this.name,
        loanDuration: this.editData?.loanDuration ? this.editData?.loanDuration : null,
        additional: this.editData?.additional ? this.editData?.additional : null,
        idCreatedBy: this.user.sub_id, // fixed
      }
    },
    customerList() {
      return this.allContact.filter(x => {
        let isFiltered = false

        if (this.filterAllContact !== '') {
          for (const field in x) {
            if (x[field] && x[field].toString().toLowerCase().includes(this.filterAllContact.toLowerCase())) {
              isFiltered = true
            }
          }
        } else {
          isFiltered = true
        }
        return isFiltered
      })
    },
    rawLoanTypes() {
      return this.$store.getters['manageLoan/getLoanTypes']
    },
    loanTypes() {
      return this.rawLoanTypes.filter(loanType => loanType.projectId && +loanType.projectId === +this.project.ID)
    },
  },
  watch: {
    loanTypes(data) {
      //console.log('watch loanTypes', data)
    },
    editData(data) {
      this.isRecipientListed = true
      if (data && data.recipient) {
        if (typeof data.recipient === 'string') {
          data.recipient = {
            name: this.recipient,
          }
        } else if (typeof data.recipient == 'object') {
          this.recipientPhoneNumber = data.recipient.phone_number
          const recipientRegistered = this.customerList.find(el => el.phone_number == data.recipient.phone_number)
          if (recipientRegistered) {
            this.recipient = recipientRegistered
          } else {
            this.recipient = data.recipient
            this.isRecipientListed = false
          }
        }
      }
      if (data && data.totalLoan) {
        this.totalLoan = data.totalLoan
      }
      this.showAdditionalSurvey = false
      if (data?.loanTypeId && data?.loanTypeId?.id) {
        this.loanTypeId = data.loanTypeId?.id
        this.isAdditionalFilled = data.additional ? true : false
      } else {
        this.isAdditionalFilled = false
        this.isAdditionalExist = false
      }
      data.editPage = true
    },
    loanTypeId(data) {
      if (this.loanSub.loanTypeId !== data) {
        this.loanSub.additional = {}
        this.isAdditionalFilled = true
      }
      this.showAdditionalSurvey = false
      this.loanSub.loanTypeId = data
      if (data) {
        const findLoanType = this.loanTypes.length > 0 ? this.loanTypes.find(el => +el.id === +data) : {}
        if (findLoanType) {
          this.bilingPeriod = findLoanType.billingPeriod ? findLoanType.billingPeriod.toLowerCase() : 'monthly'
          this.isRecuringBiling = findLoanType.billingDue !== null
          let additional = findLoanType.additional
          additional =
            typeof additional === 'string' ? JSON.parse(additional) : typeof additional === 'object' ? additional : {}
          this.surveyJsonQuestion = additional
          this.generateSurvey(additional)
          this.isAdditionalExist = Object.keys(additional).length > 0
        }
      }
    },
    totalLoan(data) {
      this.loanSub.totalLoan = +data
    },
    recipient(data) {
      if (data) {
        if (typeof data == 'object') {
          this.recipientPhoneNumber = data.phone_number
          const recipientRegistered = this.customerList.find(el => el.phone_number === this.recipientPhoneNumber)
          this.loanSub.recipient = recipientRegistered
        } else if (typeof data == 'string') {
          //console.log(data, 'data string')
          this.loanSub.recipient = {
            name: data,
          }
          //console.log(this.loanSub.recipient)
        }
      }
    },
  },
  async mounted() {
    this.generateSurvey(this.surveyJsonQuestion)
    await this.getContactList()
    // Untuk fetch cust list & group
    await this.loadCustomerListGroup()
    await this.getLoanTypes()
  },
  methods: {
    // updateTargetInput(currentValue) {
    //   this.loanSub.recipient = currentValue
    // },
    generateSurvey(json) {
      const self = this
      this.survey = new SurveyVue.Model(json)
      this.survey.data = this.loanSub.additional
      this.survey.showTitle = false
      this.survey.showPageTitles = false
      this.survey.isSinglePage = true
      this.survey.showNavigationButtons = false
      this.survey.onValueChanged.add(async function send(sender) {
        // //console.log('loansub survey completed', sender.data)
        self.loanSub.additional = sender.data
        self.isAdditionalFilled = true
        self.showAdditionalSurvey = false
        sender.clear(false, true)
      })
    },
    async getContactList() {
      try {
        const res = await this.$store.dispatch('auth/getContactList', {
          sub_id: 's8phtr3sr',
        })
        this.recipients = res.data[0].contacts
      } catch (err) {
        console.log(err)
      }
    },
    async createLoanSub() {
      //ini utk create loan sub
      if (typeof this.recipient === 'string') {
        this.loanSub.recipient = {
          name: this.recipient,
        }
      }
      //console.log(this.loanSub, 'this.loanSub')
      if (!this.editData.editPage) {
        //create loan kalo mau sekalian create customer tp masuk di default cust group
        if (!this.loanSub.recipient._uid) {
          if (!this.loanSub.recipient.name) {
            this.errMessage = 'Invalid Input Name'
            this.showSnackbarErr = true
          } else {
            this.loanSub.recipient.phone_number = this.recipientPhoneNumber
            // Ambil data all contact list untuk pengecekkan nomor yang mau ditambahkan dari contact wa atau tidak
            const query = {
              token: this.user.token,
              sub_id: this.user.sub_id,
            }
            const { data: allContactList } = await this.$store.dispatch('rooms/getRoomsContactDataPostgres', query)
            let phoneNumberForCek = ''

            if (this.loanSub.recipient.phone_number.startsWith('0')) {
              phoneNumberForCek = this.loanSub.recipient.phone_number.replace('0', '62')
            } else {
              phoneNumberForCek = this.loanSub.recipient.phone_number
            }

            let newContactModel = {
              contactName: this.loanSub.recipient.name,
              phoneNumber: this.loanSub.recipient.phone_number,
              nickname: this.loanSub.recipient.name,
              type: '',
            }
            // cek jika nomor yang mau ditambahkan sudah ada di contact wa maka set type menjadi 'Whatsapp' dan sbliknya
            const foundContact = allContactList.data.find(x => x.phone_number === phoneNumberForCek)
            if (foundContact) {
              newContactModel.type = 'Whatsapp'
            } else {
              newContactModel.type = 'Phone number'
            }
            let foundDefault = this.custList.find(x => x.list_id === 'default')
            if (!foundDefault) {
              let settings = {
                _id: 'defaultcolumns',
                key: 'defaultcolumns',
                value: [
                  {
                    id: 2,
                    name: 'name',
                    align: 'left',
                    label: 'Name',
                    field: 'name',
                    value: 'name',
                    text: 'Name',
                    sortable: true,
                    type: 'text',
                    show: true,
                    showable: false,
                    formatDate: '',
                    deleteable: false,
                    isRequired: true,
                    defaultValue: '',
                    editable: true,
                    order_number: 3,
                  },
                  {
                    id: 3,
                    name: 'phone_number',
                    align: 'left',
                    label: 'Phone Number',
                    field: 'phone_number',
                    value: 'phone_number',
                    text: 'Phone Number',
                    sortable: true,
                    type: 'text',
                    show: false,
                    showable: true,
                    formatDate: '',
                    deleteable: false,
                    isRequired: true,
                    defaultValue: '',
                    editable: false,
                    order_number: 2,
                  },
                  {
                    id: 14,
                    name: 'phone_number_show',
                    align: 'center',
                    label: 'Phone Number',
                    field: 'phone_number_show',
                    value: 'phone_number_show',
                    text: 'Phone Number',
                    sortable: true,
                    type: 'text',
                    show: true,
                    showable: true,
                    formatDate: '',
                    deleteable: false,
                    isRequired: true,
                    defaultValue: '',
                    editable: false,
                    order_number: 2,
                  },
                ],
              }

              foundDefault = {
                _id: 'default',
                list_id: 'default',
                list_name: 'default',
                contacts: [
                  {
                    name: newContactModel.phoneNumber,
                    nickname: newContactModel.nickname,
                    type: newContactModel.type,
                    _id: newContactModel.phoneNumber,
                    phone_number: newContactModel.phoneNumber,
                    phone_number_show: newContactModel.phoneNumber,
                    _uid: this.$nanoid(),
                  },
                ],
                columns: settings,
              }

              await this.loadCustomerListGroup()
              await this.$store.dispatch('global/insertOrUpdateContactList', {
                sub_id: this.user.sub_id,
                token: this.user.token,
                ...foundDefault,
              })

              this.loading = false
            } else {
              const res = await this.mappingContact(newContactModel, this.isEdit, foundDefault.contacts)
              foundDefault.contacts = res.data
              if (res.error) {
                this.showAlert(res.errorMessage)
                this.loading = false
              } else {
                const newCustomerMapped = res.data.find(el => el.phone_number == newContactModel.phoneNumber)
                this.loanSub.recipient = newCustomerMapped
                await this.$store.dispatch('global/insertOrUpdateContactList', {
                  sub_id: this.user.sub_id,
                  token: this.user.token,
                  ...foundDefault,
                })
              }
              const loanSub = await this.$store.dispatch('manageLoan/createLoanSubmissions', {
                ...this.loanSub,
                sub_id: this.user.sub_id,
                email: this.user.email,
              })
              if (loanSub.status) {
                this.$root.$emit('getLoanSubmissions')
                this.$root.$emit('closeCreateSubModal')
              }
            }

            await this.loadCustomerListGroup()
          }
        }
        //create loan kalo mau pake customer yg udah ada
        else {
          const res = await this.$store.dispatch('manageLoan/createLoanSubmissions', {
            ...this.loanSub,
            sub_id: this.user.sub_id,
            email: this.user.email,
          })
          if (res.status) {
            this.$root.$emit('getLoanSubmissions')
            this.$root.$emit('closeCreateSubModal')
          }
        }
      }
      //ini utk edit loan sub
      else {
        await this.$store.dispatch('manageLoan/editLoanSubmissions', {
          input_data: {
            ...this.loanSub,
            idLoanSub: this.editData.id,
          },
          sub_id: this.user.sub_id,
          email: this.user.email,
        })
        this.dialog = false
      }
      await this.getLoanSubmissions()
      this.resetFormInput()
      // to fetch all disbursement need approval for notifications
      await this.$store.dispatch('manageLoan/getLoanSubmissionsNeedMyApproval', {
        sub_id: this.user.sub_id,
        input_data: { my_user_data: this.user },
      })
    },
    async loadCustomerListGroup() {
      // Untuk fetch cust list & group
      const res = await this.$store.dispatch('global/getContactLists', {
        sub_id: this.user.sub_id,
        token: this.user.token,
      })
      if (res.status) {
        this.custList = res.data
      }
      this.allContact = []
      for (let list of this.custList) {
        if (list && list.contacts.length) {
          this.allContact = this.allContact.concat(
            list.contacts.map(x => {
              return {
                ...x,
                list_name: list.list_name,
                list_id: list.list_id,
                label_color: list.label_color,
              }
            }),
          )
        }
      }

      // remove duplicate by room id
      if (this.allContact.length < 5000) {
        this.allContact = this.allContact.filter(
          (room, index, that) =>
            index ===
            that.findIndex(t => t !== undefined && room !== undefined && t.phone_number === room.phone_number),
        )
      }
      this.$store.commit('global/SET_CUSTOMER_LIST_CONTACTS', this.allContact)
    },
    async getLoanTypes() {
      try {
        await this.$store.dispatch('manageLoan/getLoanTypes', {
          sub_id: this.user.sub_id,
        })
      } catch (err) {
        console.log(err)
      }
    },
    async getLoanSubmissions() {
      try {
        await this.$store.dispatch('manageLoan/getLoanSubmissions', {
          sub_id: this.user.sub_id,
        })
      } catch (err) {
        console.log(err)
      }
    },
    resetFormInput() {
      this.loanTypeId = null
      this.totalLoan = null
      // this.recipient = {}
      //TESTING
      this.recipient = null
      //TESTING
      this.recipientPhoneNumber = ''
    },
    async validatePhoneNumber() {
      if (!this.loanSub.recipient.phone_number) {
        this.recipientPhoneNumber = null

        return false
      }

      if (!this.recipientPhoneNumber) {
        return false
      }
      return true
    },
    formatPhoneNumber(num) {
      const countryCode = '62'
      if (num[0] === '0') {
        num = num.slice(1)
      } else if (num.slice(0, 2) === countryCode) {
        num = '0' + num.slice(2)
      } else if (num.slice(0, 3) === countryCode) {
        num = '0' + num.slice(3)
      } else if (num.slice(0, 4) === countryCode) {
        num = '0' + num.slice(4)
      }
      return num
    },
    showAlert(warningText) {
      this.alertText = warningText
      this.dialogAlert = true
    },
    closeShowSnackbarErr() {
      this.showSnackbarErr = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
